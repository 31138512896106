const BetSlipComponent = () => {
    return (<div className="bet_slip_data">
    <div className="col-right-inner">
        <div className="message-bet d-none">
            <h4 id="errorMsg" className="error">Your back odds cannot bet less than or the same as your lay odds for Martinez v S Kwon, Match Odds, Soonwoo Kwon</h4>
            <p id="betMsg" className="warning"></p>
        </div>
        <p className="d-none">Click on the odds to add selections to the betslip.</p>
        <div id="betSlipBoard" className="bet_slip">
            <div id="betList" className="slip-list max-heightminus131">
                <div className="bets_list_wrapper">
                    <ul className="slip-head d-flex">
                        <li className="col-bet">Back (Bet For)</li>
                        <li id="oddsHeader" className="col-odd">Odds</li>
                        <li id="runsHeader" className="col-odd">Unit:Runs</li>
                        <li className="col-stake">Stake</li>
                        <li className="col-profit text-end">Profit</li>
                    </ul>
                    <div className="team_list_wrapper blue_bg_tuch">
                        <h4><img className="icon-in_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="No_Image" /> Pakistan v West Indies</h4>
                        <div className="match_oddds">
                            <span className="d-flex align-center justify-content-center">
                                <i className="fa fa-window-close bet-slip-cross-icon"></i>
                                <h5>Pakistan<small>Match Odds</small></h5>
                            </span>
                            <div className="up_down_rate">
                                <ul>
                                    <li><input type="text" disabled="" className="bet-slip-first-input" value="1.39" /></li>
                                    <li><input type="number" name="back-tik" max="1500000" title="Enter amount" value=""  /></li>
                                </ul>
                            </div>
                            <div className="odds_value"></div>
                        </div>
                        <div className="preset_value">
                            <ul>
                                <li><button className="price-button-pay-slip">10</button></li>
                                <li><button className="price-button-pay-slip">50</button></li>
                                <li><button className="price-button-pay-slip">100</button></li>
                                <li><button className="price-button-pay-slip">200</button></li>
                                <li><button className="price-button-pay-slip">1000</button></li>
                                <li><button className="price-button-pay-slip">5000</button></li>
                            </ul>
                        </div>
                        <div className="min-bets">Min bet:1</div>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
        <div className="full_btn">
            <div className="liability sum">Liability<span className="red">$0</span></div>
            <ul className="btn-wrap">
                <li><button className="price-button-pay-slip btn">Cancel All</button></li>
                <li><button className="btn-send disable" title="" >Place Bets</button></li>
            </ul>
            <ul className="slip-option">
                <li><input type="checkbox" /><label>Please confirm your bets.</label></li>
            </ul>
        </div>
    </div>
</div>
    )
}
export default BetSlipComponent;
