import React, { useCallback, useEffect, useState } from "react";
import { useParams, Router, useLocation } from "react-router-dom";
import { sportsApi } from "../../../services/SportsService";
import { useWebsocket } from "../../../context/webSocket";
import moment from 'moment';

// Get the current date and time
const now = moment();
console.log("Current Date and Time:", now.format('YYYY-MM-DD HH:mm:ss'));

// Check if a date is before or after another
const matchDate = "2025-01-05T22:00:00.000Z";
console.log("Is match live?", moment().isBetween(matchDate, moment(matchDate).add(2, 'hours')));

const BetTechComponent = () => {
  const type = useParams();
  const [activetab, setactivetab] = useState("Inplay")
  const [matchList, setMatchList] = useState({4:[], 1:[], 2:[]})
  const { socket } = useWebsocket()
  const [odds, setOdds] = React.useState({})
  const { sportId, status } = useParams()

  const  isEventLive = (matchDateTime, durationHours = 2)  => {
    const now = new Date(); // Current time
    const startTime = new Date(matchDateTime); // Event start time  
    return now >= startTime;
  }
  const fetchMatchList = async () =>{
    const response = await sportsApi.get("get-match?sportId=&status=all&limit=")
    if(response.data.data)
    {   const filterData = {4:[], 1:[], 2:[]}
    response.data.data?.map((Item) => {
        Item["isLive"] = isEventLive(Item.match_datetime);
        filterData[Item.match_sports_id].push(Item)
    })
    const oddsData = { ...odds };
    marketIdsEvent(response.data.data, oddsData, 'joinMarketRoom');
    setOdds(oddsData);
    setMatchList(filterData)
    }
  }
  useEffect(()=>{
    fetchMatchList()
    return () => {
        const oddsData = { ...odds };
        marketIdsEvent(matchList, oddsData, 'leaveMarketRoom');
      };
  },[])

  const marketIdsEvent = (data, oddsData, event) => {
    if(data){
        data?.map((match) => {
            oddsData[match.match_api_id] = Array(6).fill('-')
            if(match.market_id)
            {
                socket.emit(event, match.market_id)
            }
          })
    }
    
  }
  
  React.useEffect(() => {
    socket.on('getMarketData', (marketData) => {
      let firstIndexFirst = '-'
      let firstIndexTwo = '-'
      let secIndexFirst = '-'
      let secfirstIndexTwo = '-'
      let thirdIndexFirst = '-'
      let thirdfirstIndexTwo = '-'

      if (marketData.runners) {
        if (marketData.runners[0] && marketData.runners[0].ex.availableToBack[0]) {
          firstIndexFirst = marketData.runners[0].ex.availableToBack[0].price
          firstIndexTwo = marketData.runners[0].ex.availableToLay[0].price
        }

        if (marketData.runners[1] && marketData.runners[1].ex.availableToBack[0]) {
          secIndexFirst = marketData.runners[1].ex.availableToBack[0].price
          secfirstIndexTwo = marketData.runners[1].ex.availableToLay[0].price
        }

        if (marketData.runners[2] && marketData.runners[2].ex.availableToBack[0]) {
          thirdIndexFirst = marketData.runners[2].ex.availableToBack[0].price
          thirdfirstIndexTwo = marketData.runners[2].ex.availableToLay[0].price
        }
      }
     
      setOdds((prev) => ({
        ...prev,
        [marketData.marketId]: [
            firstIndexFirst,
            firstIndexTwo,
            thirdIndexFirst,
            thirdfirstIndexTwo,
            secIndexFirst,
            secfirstIndexTwo,
          ]
    }))
    })
    return () => {
      socket.off('getMarketData')
    }
  }, [odds])

  const memoOdds = useCallback(
    (marketId) => {
      const marketData = odds[marketId]
      console.log(odds)
      return (
        <>
        <dd className="col-draw">
            <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[0]}</button>
            <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[1]}</button>
        </dd>
        <dd className="col-draw">
            <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[2]}</button>
            <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[3]}</button>
        </dd>
        <dd className="col-draw">
            <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[4]}</button>
            <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[5]}</button>
        </dd>
        </>
      )
    },
    [odds],
  )
  const renderSports = (sportId) => {
   return matchList[sportId]?.map((Item) => {
        return <dl className="game-list-col d-flex">
        <dt className="col-matched">
            <a href={`/markets/${Item.match_api_id}`}>
                <img className={`${Item.isLive ? 'icon-in_play':''}`} src="../../imgs/transparent.gif" alt="No_Image" />
                <span className="game-titlesmall">{Item.match_name}</span>
                {Item.isLive && <><span className="in_play">In-Play </span><span className="game-live"></span></>}
                {!Item.isLive && <span class="game-list-time">{moment(Item.match_datetime).format("YYYY-MM-DD HH:mm")}</span>}
            </a>
        </dt>
        <dd className="col-mdl text-end">USD 0</dd>
        {memoOdds(Item?.market_id)}
        <dd className="col-info"><a className="add-pin" href="/inplay"></a></dd>
    </dl>
    })
  }
  return (
    <div>
     <div className="full-wrap without-left-nav inplay_page_wrap">
    <div className="col-center inplay">
        <div className="theme_tabs">
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item" role="presentation" onClick={()=>setactivetab("Inplay")}>
                    <button type="button" id="react-aria1396711951-7-tab-inplay" role="tab" data-rr-ui-event-key="inplay" aria-controls="react-aria1396711951-7-tabpane-inplay" aria-selected="true" className={`nav-link ${activetab=='Inplay'?'active':''}`}>Inplay</button>
                </li>
                <li className="nav-item" role="presentation"  onClick={()=>setactivetab("Today")}>
                    <button type="button" id="react-aria1396711951-7-tab-today" role="tab" data-rr-ui-event-key="today" aria-controls="react-aria1396711951-7-tabpane-today" aria-selected="false" tabindex="-1" className={`nav-link ${activetab=='Today'?'active':''}`}>Today</button>
                </li>
                <li className="nav-item" role="presentation"  onClick={()=>setactivetab("tomorrow")}>
                    <button type="button" id="react-aria1396711951-7-tab-tomorrow" role="tab" data-rr-ui-event-key="tomorrow" aria-controls="react-aria1396711951-7-tabpane-tomorrow" aria-selected="false" tabindex="-1" className={`nav-link ${activetab=='tomorrow'?'active':''}`}>
                        Tomorrow
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                <div role="tabpanel" id="react-aria1396711951-7-tabpane-inplay" aria-labelledby="react-aria1396711951-7-tab-inplay" className="fade tab-pane active show">
                    <div className="theme_accordian">
                        <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Cricket</button></h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="game_table_main_wrapper">
                                            <div className="game_table_inner">
                                                <ul className="slip-head d-flex">
                                                    <li className="col-game"></li>
                                                    <li className="col-matched text-end">Matched</li>
                                                    <li className="col-visit text-center">1</li>
                                                    <li className="col-draw text-center">x</li>
                                                    <li className="col-home text-center">2</li>
                                                    <li className="col-info"></li>
                                                </ul>
                                                <div className="game-list">
                                                   {renderSports(4)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Tennis</button></h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="game_table_main_wrapper">
                                            <div className="game_table_inner">
                                                <ul className="slip-head d-flex">
                                                    <li className="col-game" ></li>
                                                    <li className="col-matched text-end">Matched</li>
                                                    <li className="col-visit text-center">1</li>
                                                    <li className="col-home text-center">2</li>
                                                    <li className="col-info"></li>
                                                </ul>
                                                <div className="game-list">
                                                   {renderSports(2)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Soccer</button></h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="game_table_main_wrapper">
                                            <div className="game_table_inner">
                                                <ul className="slip-head d-flex">
                                                    <li className="col-game"></li>
                                                    <li className="col-matched text-end">Matched</li>
                                                    <li className="col-visit text-center">1</li>
                                                    <li className="col-draw text-center">x</li>
                                                    <li className="col-home text-center">2</li>
                                                    <li className="col-info"></li>
                                                </ul>
                                                <div className="game-list">
                                                   {renderSports(1)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="tabpanel" id="react-aria1396711951-7-tabpane-today" aria-labelledby="react-aria1396711951-7-tab-today" className="fade tab-pane">
                    <div className="today_tab">
                        <div className="sports_filters">
                            <div className="d-flex">
                                <h5>Sports Filters:</h5>
                                <ul className="d-flex align-items-center">
                                    <li>Cricket</li>
                                    <li>Soccer</li>
                                    <li>Tennis</li>
                                </ul>
                            </div>
                            <div className="ms-auto mb-2"><button type="button" className="close_btn btn btn-primary">Filter</button></div>
                        </div>
                    </div>
                    <table className="today_table bg-white table table-fixed table-hover table">
                        <tbody>
                            <tr>
                                <td>11:30 PM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Portuguese Primeira Liga<span className="angle_unicode">▸</span><a href="/markets/soccer/33886632/1.237503789">Braga v Casa Pia</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>11:30 PM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Egyptian Premier<span className="angle_unicode">▸</span><a href="/markets/soccer/33891001/1.237569305">Zamalek v Al Ittihad (EGY)</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>11:30 PM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Friendly Matches<span className="angle_unicode">▸</span><a href="/markets/soccer/33898850/1.237676941">Sergipe v SC Penedense</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>11:45 PM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Israeli Cup<span className="angle_unicode">▸</span><a href="/markets/soccer/33895838/1.237644952">MSK Kiryat Yam v Beitar Jerusalem</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Italian Serie B<span className="angle_unicode">▸</span><a href="/markets/soccer/33888005/1.237542219">Sampdoria v Pisa</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Friendly Matches<span className="angle_unicode">▸</span><a href="/markets/soccer/33897044/1.237654646">Nautico PE v Campinense</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/soccer/33895387/1.237628352">RSB Berkane v Raja Casablanca</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Italian Serie A<span className="angle_unicode">▸</span><a href="/markets/soccer/33871068/1.237255639">AC Milan v Roma</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>English Championship<span className="angle_unicode">▸</span><a href="/markets/soccer/33884957/1.237484507">Middlesbrough v Burnley</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Jamaican Premier League<span className="angle_unicode">▸</span><a href="/markets/soccer/33895892/1.237644499">Harbour View FC v Montego Bay United</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Jamaican Premier League<span className="angle_unicode">▸</span><a href="/markets/soccer/33895896/1.237644409">Portmore Utd v Cavalier</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Jamaican Premier League<span className="angle_unicode">▸</span><a href="/markets/soccer/33895957/1.237644319">Tivoli Gardens v Humble Lions</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Jamaican Premier League<span className="angle_unicode">▸</span><a href="/markets/soccer/33896013/1.237644229">Vere United FC v Molynes United FC</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Jamaican Premier League<span className="angle_unicode">▸</span><a href="/markets/soccer/33897814/1.237666661">Dunbeholden FC v Racing United FC</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Friendly Matches<span className="angle_unicode">▸</span><a href="/markets/soccer/33898832/1.237677121">Asa AL v Confianca</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>
                                            Cricket<span className="angle_unicode">▸</span>SA T20 League SRL<span className="angle_unicode">▸</span>
                                            <a href="/markets/cricket/-10774857/-10774903">Pretoria Capitals SRL T20 v Durban Super Giants SRL T20</a>
                                        </h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Portuguese Primeira Liga<span className="angle_unicode">▸</span><a href="/markets/soccer/33888557/1.237545142">Sporting Lisbon v Benfica</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>
                                            Cricket<span className="angle_unicode">▸</span>Caribbean Premier League SRL<span className="angle_unicode">▸</span>
                                            <a href="/markets/cricket/-10774869/-10774915">Barbados Royals SRL T20 v St Lucia Kings SRL T20</a>
                                        </h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>WTA Auckland 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33898121/1.237669461">Stefanini v C Tauson</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Jamaican Premier League<span className="angle_unicode">▸</span><a href="/markets/soccer/33896004/1.237644589">Waterhouse FC v Mount Pleasant FC</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>United Cup<span className="angle_unicode">▸</span><a href="/markets/tennis/33897289/1.237660066">Katie Boulter v Nadia Podoroska</a></h5>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div role="tabpanel" id="react-aria1396711951-7-tabpane-tomorrow" aria-labelledby="react-aria1396711951-7-tab-tomorrow" className="fade tab-pane">
                    <div className="today_tab">
                        <div className="sports_filters">
                            <div className="d-flex">
                                <h5>Sports Filters:</h5>
                                <ul className="d-flex align-items-center">
                                    <li>Cricket</li>
                                    <li>Soccer</li>
                                    <li>Tennis</li>
                                </ul>
                            </div>
                            <div className="ms-auto mb-2"><button type="button" className="close_btn btn btn-primary">Filter</button></div>
                        </div>
                    </div>
                    <table className="today_table bg-white table table-fixed table-hover table">
                        <tbody>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Jamaican Premier League<span className="angle_unicode">▸</span><a href="/markets/soccer/33896015/1.237644679">Arnett Gardens FC v Chapelton</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>WTA Auckland 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33895527/1.237636340">Lulu Sun v Re Marino</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>WTA Auckland 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33898111/1.237669173">Pera v Jimenez Kasintseva</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/tennis/33896145/1.237643431">Pris Hon v Birrell</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896484/1.237647265">Luca van Assche v Sweeny</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/tennis/33895611/1.237637945">A Kalinina v Yaf Wang</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/tennis/33895616/1.237637948">V Kudermetova v Pe Stearns</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/tennis/33896963/1.237653112">Thompson v Berrettini</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896342/1.237645841">Eubanks v Al Kovacevic</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896464/1.237647071">Galan v Collignon</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896474/1.237647090">Fearnley v Dellavedova</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896479/1.237647178">Ma Landaluce v Ma Bellucci</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>United Cup<span className="angle_unicode">▸</span><a href="/markets/tennis/33897290/1.237660340">Maria Sakkari v Elena Rybakina</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/tennis/33896114/1.237643054">Todoni v Bucsa</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896363/1.237645877">Kopriva v Krueger</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Nonthaburi Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33898655/1.237673706">Coppejans v Ra Ho</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/tennis/33895626/1.237638136">Y Yuan v A Rus</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>WTA Auckland 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33895517/1.237635879">A Li v Stephens</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Cricket<span className="angle_unicode">▸</span>International Twenty20 Matches<span className="angle_unicode">▸</span><a href="/markets/cricket/33896086/1.237646637">New Zealand v Sri Lanka</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Cricket<span className="angle_unicode">▸</span>Bangladesh Premier League<span className="angle_unicode">▸</span><a href="/markets/cricket/33884986/1.237485041">Fortune Barishal v Durbar Rajshahi</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/tennis/33896140/1.237643423">A Potapova v A Bogdan</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Cricket<span className="angle_unicode">▸</span>Twenty20 Big Bash<span className="angle_unicode">▸</span><a href="/markets/cricket/33894377/1.237638550">Sydney Thunder v Melbourne Renegades</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>United Cup<span className="angle_unicode">▸</span><a href="/markets/tennis/33898967/1.237677914">Zhizhen Zhang v Alexander Zverev</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Cricket<span className="angle_unicode">▸</span>Bangladesh Premier League<span className="angle_unicode">▸</span><a href="/markets/cricket/33884998/1.237485329">Rangpur Riders v Dhaka Division</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Cricket<span className="angle_unicode">▸</span>Barbados T10<span className="angle_unicode">▸</span><a href="/markets/cricket/33898757/1.237677638">Titans v Warriors</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tomorrow</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Soccer<span className="angle_unicode">▸</span>Italian Serie A<span className="angle_unicode">▸</span><a href="/markets/soccer/33871329/1.237259767">Como v Lecce</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tuesday at 5:00 AM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896420/1.237646546">Ajdukovic v Diaz Acosta</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tuesday at 5:00 AM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896454/1.237647013">Hug Gaston v Yo Watanuki</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tuesday at 5:00 AM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>Tennis<span className="angle_unicode">▸</span>Canberra Challenger 2025<span className="angle_unicode">▸</span><a href="/markets/tennis/33896459/1.237646979">Mac McDonald v Jo Fonseca</a></h5>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tuesday at 5:10 AM</td>
                                <td>
                                    <div className="table_data">
                                        <h5>
                                            Cricket<span className="angle_unicode">▸</span>ATP Pozoblanco Challenger<span className="angle_unicode">▸</span><a href="/markets/cricket/33896498/1.237673404">Otago Sparks Women v Central Hinds Women</a>
                                        </h5>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <button className="rightbar_btn d-block d-lg-none"><i className="fa-solid fa-bars"></i></button>
    <div className="col-right">
        <div className="theme_accordian">
            <div className="accordion">
                <div className="accordion-item">
                    <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Bet Slip</button></h2>
                    <div className="accordion-collapse collapse show">
                        <div className="accordion-body">
                            <div className="bet_slip_data">
                                <div className="col-right-inner">
                                    <div className="message-bet d-none">
                                        <h4 id="errorMsg" className="error">Your back odds cannot bet less than or the same as your lay odds for Martinez v S Kwon, Match Odds, Soonwoo Kwon</h4>
                                        <p id="betMsg" className="warning"></p>
                                    </div>
                                    <p className="d-none">Click on the odds to add selections to the betslip.</p>
                                    <div id="betSlipBoard" className="bet_slip">
                                        <div id="betList" className="slip-list max-heightminus131">
                                            <div className="bets_list_wrapper"><div></div></div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-item-center mt-2 px-3"><p >Click on the odds to add selections to the betslip.</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div></div>
</div>

    </div>
  );
};

export default BetTechComponent;
