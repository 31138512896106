import React, { useCallback, useEffect, useState } from "react";
import { useParams, Router, useLocation } from "react-router-dom";
import { sportsApi } from "../../../services/SportsService";
import { useWebsocket } from "../../../context/webSocket";
import BetSlipComponent from "./betslip";

const Odds = () => {
    const type = useParams();
    const [activetab, setactivetab] = useState("Inplay")
    const [matchList, setMatchList] = useState([])
    const [matchInfo, setMatchInfo] = useState({})
    const { socket } = useWebsocket()
    const [odds, setOdds] = React.useState({})
    const { matchId, status } = useParams()


    const fetchMatchList = async () => {
        if (!matchId) return
        const response = await sportsApi.get(`get-market-list?matchId=${matchId}`)
        if (response.data.data) {
            setMatchList(response.data.data.markets)
            setMatchInfo(response.data.matchInfo)
            marketIdsEvent(response.data.data.markets)
        }
    }
    useEffect(() => {
        fetchMatchList()
        return () => {
            socket.off('getMarketData')
            leaveMarketRoom()
        }
    }, [matchId])


    const marketIdsEvent = (data) => {
        const selections = {}
        const profitLoss = {}
        const remarkMarket = {}
        let runnersName = {}
        data.forEach(async (market) => {
            const runners = JSON.parse(market.runnners)
            runners.forEach((runner) => {
                runnersName = {
                    ...runnersName,
                    [market.marketId]: {
                        ...runnersName[market.marketId],
                        [runner.selectionId]: runner.runnerName,
                    },
                }
                selections[market.marketId] = market
                profitLoss[runner.selectionId] = 0
            })
            remarkMarket[market.marketId] = ''
        })
        const state = {
            runnersData: selections,
            runnersDataPrev: JSON.parse(JSON.stringify(selections)),
            profitLoss,
            getMarketBook: {},
            remarkMarket: remarkMarket,
            runnersName: runnersName,
        }
        setOdds(state)
        setTimeout(() => {
            socketEvents()
        }, 1000)
        console.log(state)
    }

    const getSocketEvents = () => {
        const handler = (market) => {
            setOdds((prev) => ({
                ...prev,
                runnersData: { ...prev.runnersData, [market.marketId]: market },
            }))
        }
        socket.on('getMarketData', handler)
    }

    const availableToBack = (selections) => {
        return selections?.map((back, index) => {
            let cls = index == 0 ? 'back-3' : `back-2`
            cls = index == 1 ? 'back-2  hide-on-small-only' : cls
            cls = index == 2 ? 'back-1s ' : cls
            //   const blinkCls = back.changed ? 'blink' : ''
            return (
                <td value={back.price} id={`back_odds_${back.price}`} className={`${cls} bet-cursor mobile_hide border-0 false`} style={{ cursor: "not-allowed" }}><a>{back.price}<span>{back.size}</span></a></td>
            )
        })
    }

    const availableToLay = (selections) => {
        console.log(odds)
        return selections?.map((lay, index) => {
            const i = index
            let cls = index == 0 ? 'lay-1' : `lay-${index}`
            cls = index == 1 ? 'lay-2  hide-on-small-only' : cls
            cls = index == 2 ? 'lay-3  hide-on-small-only' : cls
            //   const blinkCls = lay.changed ? 'blink' : ''
            //   if (isMobile && index != 0) return
            return (
                <td value={lay.price} id={`lay_odds_${lay.price}`} className={`${cls} bet-cursor mobile_hide border-0 false`} style={{ cursor: "not-allowed" }}><a>{lay.price}<span>{lay.size}</span></a></td>
            )
        })
    }
    const renderRunners = (runners, selectionIdName) => {
        return runners?.map((Item) => {
            const name = selectionIdName?.[Item.selectionId]
            console.log(Item, "ItemItemItem")
            return <tr className="relative_row">
                <th className="">
                    <p><a href="#"><img className="icon-predict" src="../../imgs/transparent.gif" /></a>{name}
                        <div style={{ display: "flex" }}><span style={{ color: "green", marginRight: "5px" }} ></span><span style={{ color: "green" }}></span></div>
                    </p>
                </th>
                <td className="relative_for p-0">
                    <table>
                        <tr>
                            {availableToBack(Item?.ex?.availableToBack)}
                        </tr>
                    </table>
                    {Item.status=="SUSPENDED" && <div class="hide-data-table ps-0"><table class="w-100 text-center"><tbody><tr><td class="pe-xxl-5 pe-3">Suspended</td></tr></tbody></table></div>}

                </td>
                <td className="relative_for p-0">
                    <table>
                        <tr>
                            {availableToLay(Item?.ex?.availableToLay)}
                        </tr>
                    </table>
                </td>
            </tr>
        })
    }
    const renderSports = (sportId) => {
        return matchList?.map((Item) => {
            const runner = JSON.parse(Item.runnners)
            const oddsDataNew = odds ? odds?.runnersData?.[Item.marketId] : null
            const selectionIdName = odds?.runnersName?.[Item.marketId]
            console.log(oddsDataNew,"oddsDataNew")
            return <div><div className="top-wrapper-match bg-white mb-3 mt-2">
                <ul className="match-btn d-flex justify-content-center">
                    <li><a className="btn-pin" href="#"><img src="/static/media/pin1.3787342a9251a038ff5fa4c6954f71d4.svg" alt="" /></a></li>
                    <li><a className="btn-refresh" href="#"> <img src="/static/media/refresh.d1ac819a6c793c0332d045380510641e.svg" alt="" /></a></li>
                </ul>
                <div className="match-odd-scroller">
                    <div className="match-top-wrapper d-flex align-items-center">
                        <div className="odd-sec"><strong>{Item.marketName}</strong><span> In-Play</span></div>
                        <div className="max">
                            <h6 className="mb-0">Max 5000.00</h6>
                        </div>
                        <div className="live-match"><span>Matched</span><strong>USD 44433.24</strong></div>
                    </div>
                </div>
            </div>
                <div className="match-odds-table game_table_main_wrapper">
                    <table className="bets game_table_inner">
                        <tbody>
                            <tr className="bet-all" style={{ background: "rgb(240, 236, 225)" }}>
                                <td className="text-start">2 selections - {Item.marketName}</td>
                                <td className="px-0">
                                    <table>
                                        <tr>
                                            <td id="backPercent" className="refer-bet mobile_hide">100.6%</td>
                                            <td className="back-all_td"><a id="backAll" className="back-all"><i className="backall-shape"></i><span>Back all</span></a></td>
                                        </tr>
                                    </table>
                                </td>
                                <td className="px-0">
                                    <table>
                                        <tr>
                                            <td className="lay-all_td"><a id="layAll" className="lay-all"><i className="layall-shape"></i><span>Lay all</span></a></td>
                                            <td id="layPercent" className="refer-book mobile_hide" colspan="2">99.9%</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            {renderRunners(oddsDataNew?.runners, selectionIdName)}
                        </tbody>
                    </table>
                </div></div>
        })
    }
    const socketEvents = useCallback(() => {
        // Define your socket logic
        leaveMarketRoom()
        joinMarketRoom()
        getSocketEvents()
        socket.on('connect', () => {
            joinMarketRoom()
        })
        socket.on('reconnect', () => {
            joinMarketRoom()
        })
    }, []);

    const joinMarketRoom = () => {
        if (matchId) {
            socket.emit('joinRoom', matchId)
        }
        matchList.forEach(async (market) => {
            socket.emit('joinMarketRoom', market.marketId)
        })
    }
    const leaveMarketRoom = () => {
        matchList.forEach(async (market) => {
            socket.emit('leaveRoom', market.marketId)
            socket.emit('leaveRoom', market.matchId)
        })
    }
    return (
        <div>
            <div className="full-wrap">
                <div className="match-sidebar">
                    <ul className="mb-0">
                        <div>
                            <h6>Sports</h6>
                            <li><a href="/sportsbook">All Sports</a> </li>
                            <li className=""><a href="/sportsbook/cricket">Cricket</a> </li>
                            <li className=""><a href="/sportsbook/soccer">Soccer</a> </li>
                            <li className=""><a href="/sportsbook/tennis">Tennis</a> </li>
                            <div>
                                <li><a href="/markets/cricket/33912757/1.237889346">{matchInfo?.match_series_name}</a> </li>
                                <li className="active"><a href={`/markets/${matchId}`}>{matchInfo?.match_name}</a> </li>
                                {matchList.length > 0 && matchList?.map((Item) => {
                                    return <li className="">
                                        <a href={`/markets/${matchId}`}>
                                            <img className="icon-no_play" src="../assets/images/transparent.gif" />{Item.marketName}
                                        </a> </li>
                                })}
                            </div>
                            <div></div>
                        </div>
                    </ul>
                </div>
                <div className="col-center games_center_block">
                    <div className="py-2">
                        <iframe className="responsive-iframe w-100" src={`https://www.satsports.net/score_widget/index.html?id=${matchId}`} style={{ height: "180px" }}>
                        </iframe>
                    </div>
                    {matchList?.length > 0 && renderSports()}
                    <div className="sportsbook-head  mt-3 d-flex align-items-center "></div>
                </div>
                <button className="rightbar_btn d-block d-lg-none"><i className="fa-solid fa-bars"></i></button>
                <div className="col-right">
                    <div className="theme_accordian">
                        <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Bet Slip</button></h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bet_slip_data">
                                            <div className="col-right-inner">
                                                <div className="message-bet d-none">
                                                    <h4 id="errorMsg" className="error">Your back odds cannot bet less than or the same as your lay odds for Martinez v S Kwon, Match Odds, Soonwoo Kwon</h4>
                                                    <p id="betMsg" className="warning"></p>
                                                </div>
                                                <p className="d-none">Click on the odds to add selections to the betslip.</p>
                                                <div id="betSlipBoard" className="bet_slip">
                                                    <div id="betList" className="slip-list max-heightminus131">
                                                        <div className="bets_list_wrapper"><div></div></div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center align-item-center mt-2 px-3"><p >Click on the odds to add selections to the betslip.</p></div>
                                            </div>
                                        </div>

                                            <BetSlipComponent/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>

        </div>
    );
};

export default Odds;
