import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

// Create the context
export const SocketContext = createContext({});

// WebSocketProvider Component
export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io("https://bettingsitescript.site/", {
      transports: ['websocket'],
    });
    setSocket(newSocket);

    // Cleanup on unmount
    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {socket && children}
    </SocketContext.Provider>
  );
};

// Custom hook to use the WebSocket context
export const useWebsocket = () => useContext(SocketContext);
